@import "admiral/style.css";
@import "antd/dist/reset.css";

.disabled-row {
  opacity: 30%;
  pointer-events: none;
}

.table-row-top {
  vertical-align: top;
}
